import $ from "jquery";

class Quiz {
  constructor() {
    this.currentStep = null;
    this.steps = [];
    this.container = null;

    this.back = this.back.bind(this);
    this.setup = this.setup.bind(this);
  }

  setup() {
    this.container = $("#steps-container");
    this.errorContainer = $("#step-errors");
    $("#back_step").click(this.back);
    bind_resize();
  }

  renderStep({ template, stage, id }) {
    this.errorContainer.addClass("d-none");

    if (this.currentStep) {
      this.currentStep.template
        .removeClass("current-step")
        .removeClass("new-step")
        .addClass("previous-step");
      this.steps.push(this.currentStep);
    }

    this.currentStep = {
      template: $(template),
      stage,
      id
    };

    this.currentStep.template
      .appendTo(this.container)
      .addClass("current-step")
      .addClass("new-step");

    bind_positive_number_check();
    adjust_content_height();
  }

  renderErrors(message) {
    this.errorContainer.text(message).removeClass("d-none");
  }

  back() {
    if (this.steps.length) {
      const lastStep = this.steps.pop();
      const lastActiveStep = this.currentStep;
      lastActiveStep.template
        .removeClass("current-step")
        .removeClass("previous-step")
        .addClass("exit");
      lastStep.template.addClass("current-step");
      this.currentStep = lastStep;
      this.setStage;
      this.errorContainer.addClass("d-none");
      adjust_content_height();
      setTimeout(function () {
        lastActiveStep.template.remove();
      }, 500);
    } else {
      window.location = "/";
    }
  }
}

window.quiz = new Quiz();
window.$ = $;

$(document).on("turbolinks:load", function () {
  quiz.setup();
  adjust_content_height();
  bind_tons_to_total();
  update_total_field();
  hide_notifications_with_time(2000);
});

function hide_notifications_with_time(t) {
  setTimeout(function () {
    hide_notifications();
  }, t);
}

function hide_notifications() {
  $(".notification").addClass("d-none");
}
function check_for_negatives(e) {
  if (!($(e).val() == "")) {
    $(e).val(Math.max($(e).val(), 0));
  }
}

function adjust_content_height() {
  $("#steps-container").height($(".current-step").outerHeight());
}

function bind_positive_number_check() {
  $(".positive-number").change(function () {
    check_for_negatives(this);
  });
}

function update_total_field() {
  $("#proposed-total").val(
    (
      $("#compensated-tons").val() *
      parseFloat($("#proposed-total").data("toncost"))
    ).toFixed(2)
  );
}
function bind_tons_to_total() {
  $("#compensated-tons").on("keyup", function () {
    update_total_field();
  });
}

function bind_resize() {
  $(window).resize(function () {
    adjust_content_height();
  });
}

import $ from "jquery";
import consumer from "channels/consumer";

class PaymentForm {
  constructor(form) {
    this.form = form;
  }

  setup() {
    this.setupStripe();
    this.setupElements();
    this.setupForm();
    this.setupActionCable();
  }

  setupStripe() {
    this.stripe = Stripe(this.form.data("stripe-key"));
  }

  setupActionCable() {
    redirect_url = this.form.attr("action");
    consumer.subscriptions.create(
      {
        channel: "CertificateChannel",
        quiz_id: this.form.data("track-certificate")
      },
      {
        received(data) {
          success_redirect();
        }
      }
    );
  }

  setupElements() {
    const elements = this.stripe.elements({ locale: this.form.data("locale") });
    this.card = elements.create("card");
    this.card.mount("#card-element");
    this.card.on("change", this.onCardChange.bind(this));
  }

  setupForm() {
    this.form.on("submit", this.onSubmit.bind(this));
  }

  async onSubmit(e) {
    e.preventDefault();

    this.loading();
    const result = await this.confirmCardPayment();

    if (result.error) {
      this.loaded();
      $("#card-error").text(result.error.message);
    }
  }

  loading() {
    const submit = this.form.find('button[type="submit"]');
    submit.attr("disabled", true);
    submit.find(".button-text").addClass("d-none");
    submit.find(".spinner").removeClass("d-none");
  }

  loaded() {
    const submit = this.form.find('button[type="submit"]');
    submit.attr("disabled", false);
    submit.find(".spinner").addClass("d-none");
    submit.find(".button-text").removeClass("d-none");
  }

  confirmCardPayment() {
    const clientSecret = this.form.data("client-secret");
    return this.stripe.confirmCardPayment(clientSecret, {
      payment_method: { card: this.card }
    });
  }

  completePayment() {
    window.location = this.form.attr("action");
  }

  onCardChange(event) {
    const errorMessage = event.error ? event.error.message : "";

    this.form.find("button").attr("disabled", event.empty);
    $("#card-error").text(errorMessage);
  }
}

function ready() {
  const $form = $("#payment-form");
  if ($form.length > 0) {
    const paymentForm = new PaymentForm($form);
    paymentForm.setup();
  }
}

var redirect_url = "";

var success_redirect = function () {
  window.location = redirect_url;
};

$(document).on("turbolinks:load", ready);

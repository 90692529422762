import $ from "jquery";

function onCarModelChange(e) {
  const model = e.target.value;
  const $yearSelect = $("[data-model-years]");
  if (!model || !$yearSelect.length) {
    return;
  }

  const availableYears = $yearSelect.data("model-years")[model];

  if (!availableYears) {
    return;
  }
  const options = availableYears.map(
    (year) => `<option value="${year}">${year}</option>`
  );
  $yearSelect.html(options);
}

$(document).on("change", ".car-model-select", onCarModelChange);
